import React from 'react';
import styled from 'styled-components/macro';

import { ArrowRightAlt } from '@material-ui/icons';
import { Button } from '@material-ui/core';

import { data } from './const/BusinessFlatRateData';
import TitleWithIcon from '../../components/TitleWithIcon';

import DispatchFlatRates from '../../assets/img/DispatchFlatRates.png';
import FlatRates from '../shared/FlatRates';
import colors from '../../assets/colors';
import fontSize from '../../assets/fontSize';

const Container = styled.div`
  max-width: 1054px;
  margin: 0 auto;
  width: 88%;
`;
const PrimaryColor = styled.span`
  color: ${colors.primary};
`;
const Description = styled.p`
  font-size: ${fontSize.xLarge};
  margin: 0;
`;
const StyledButton = styled(Button)`
  &.button {
    margin: auto;
    margin-top: 3rem;
    color: ${colors.primary};
    display: flex;
  }
`;
const ArrowRight = styled(ArrowRightAlt)`
  color: ${colors.primary} !important;
  margin-left: 1rem;
  font-size: 30px !important;
`;

const DistanceFlatRate = () => {
  return (
    <Container id="bulk-flat-rates">
      <TitleWithIcon
        title="Dispatch Flat Rates"
        icon={DispatchFlatRates}
        description={
          <Description>
            See our pick up coverage area within{' '}
            <PrimaryColor>Klang Valley, Penang</PrimaryColor> and{' '}
            <PrimaryColor>Johor Bahru.</PrimaryColor>
          </Description>
        }
      />
      <FlatRates data={data} />
      <StyledButton
        className="button"
        href={
          'https://gogetmy.zendesk.com/hc/en-us/articles/115006801327-What-is-your-coverage-area-'
        }
      >
        SEE FULL DELIVERY COVERAGE AREA
        <ArrowRight />
      </StyledButton>
    </Container>
  );
};

export default DistanceFlatRate;
